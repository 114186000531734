/*================DARK THEME STYLING=====================*/
[data-theme="dark"] {
  input.form-control {
    color: #b2d4ff !important;
  }
  textarea.form-control {
    color: #b2d4ff !important;
  }
  .zui-table tfoot td {
    color: #b2d4ff !important;
  }
  .c-app {
    background: #1f2537 !important;
  }
  .c-header,
  .c-sidebar {
    background: #020517;
    border-bottom: 0px;
  }
  .invoicedata input.form-control {
    color: #9d9d9d;
  }
  .c-footer a {
    color: #a5a8ad;
  }
  .c-body {
    background: #1f2537;
  }
  .tablehideinput input.form-control {
    background: #020517 !important;
  }
  .auditdisplayitem:nth-child(2n) {
    background: #1e1e25;
    border: 1px solid #1e1e25;
  }
  .auditdisplay {
    border: 1px solid #5d5d5d !important;
  }
  .imageattachments img {
    border: 1px solid #1e1f35;
  }
  .approvalwrap,
  .itemattach {
    border: 1px solid #5d5d5d;
  }
  .attachlinks a,
  .attachlinks {
    color: #9e9e9e;
  }
  .auditdisplayitem {
    color: #afb0b1;
    font-weight: lighter;
    border: 0px;
  }
  .auditdisplay {
    border: 1px solid #77777f;
  }
  .paysliplists .card-text {
    font-size: 12px !important;
  }
  .invoice_grandtotal {
    color: #000 !important;
  }
  .paysliplists h4.card-title {
    color: #c6c6c6 !important;
  }
  h5.respayment {
    color: #c6c6c6 !important;
    font-weight: normal;
  }
  .paysliplists .my-3 {
    margin-bottom: 1rem !important;
    border: 1px solid #6e6d6d !important;
    margin-bottom: 30px !important;
  }
  .paysliplists .my-3:hover {
    border: 1px solid #ffffff !important;
  }
  .printable.payslipcard.card {
    background: none !important;
    border: 1px solid #ccc !important;
  }
  .paysliplists .col-sm-6.col-lg-4:nth-child(2n) .card,
  .paysliplists .col-sm-6.col-lg-4:nth-child(2n) .card header.card-header {
    background: #1e1e25;
  }
  .aboutpage p,
  .aboutpage h3.h5 {
    color: #9e9e9e !important;
  }
  .payslipcard header.card-header {
    border-bottom: 1px solid #ccc;
  }
  .paysliptabledata th,
  .paysliptabledata td {
    border: 0px !important;
    border-bottom: 1px solid #d5d5d5 !important;
    color: #131313 !important;
  }
  .income_templates .printable.card,
  .income_templates .bankinfo .card-body thead {
    background: none;
  }
  .invoicetemplateone .temponemedal p,
  .invoicetemplateone .invoiceheaders p {
    color: #131313 !important;
  }
  .invoicetemplateone .payterms td {
    border-top: 0px !important;
    color: #343434;
  }
  .invoicetemplatethree .bankinfo p {
    margin: 6px 0px;
    color: #343434 !important;
  }
  header.receipttwo_header.card-header {
    background: #fff !important;
  }
  .reciept_temp_three .printable.card {
    border: 0px;
    background: #fff;
  }
  tr.invoicetotals {
    background: #5d5c5c;
  }

  .paytempone .card-body {
    background: #fff;
  }

  .payslipfooter .table-bordered td {
    border: 0px !important;
    border-bottom: 1px solid !important;
    border-color: #c6c6c6 !important;
  }

  // .income_templates .table-bordered td, .income_templates .table-bordered th {
  //   border: 1px solid !important;
  //   border-color: #dfdddd !important;
  // }
  .income_templates .temponelogic .table-bordered td,
  .income_templates .temponelogic .table-bordered th {
    color: #343434;
  }
  .invoicetemplatethree .billinginfo p {
    color: #343434 !important;
  }
  // .card-body p, .card-body h3 {
  //   color: #9e9e9e !important;
  // }
  .c-header .c-header-nav .c-header-nav-link,
  .c-header .c-header-nav .c-header-nav-btn,
  .c-sidebar .c-sidebar-nav-title {
    color: #9e9e9e !important;
  }
  .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
  .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: rgb(255 255 255 / 60%) !important;
  }

  .c-sidebar .c-sidebar-nav-link.c-active,
  .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
    font-weight: normal;
    background: #1574da;
    color: #ffffff !important;
  }

  .c-footer {
    color: #afb0b1;
    background: #1e1e29;
    border-top: 1px solid #181924;
  }

  .incomeformswrap .invoicedata td {
    color: #fff !important;
  }
  .invoicetotals td {
    color: #000 !important;
  }

  .header-title,
  .card-header {
    font-size: 1.4rem;
    // background: #20202a;
    // border-color: #181924;
    color: #afb0b1;
  }
  .card {
    background-color: #020517;
    border-color: #020517;
  }
  .image-Uploaded-card {
    border: 1px solid #5a7fa8;
  }
  .bulk-request-card {
    border: 1px solid #3c4156;
  }
  .main-card-header-text {
    color: #d1e3f7;
  }
  .bulk-request-left-tab {
    color: #ffffff;
  }
  .bulk-request-right-tab {
    color: #3c4156;
  }
  input[type="date"].form-control {
    // background-image: linear-gradient(90deg, black, #64595978);
    color-scheme: dark;
  }
  // ::-webkit-calendar-picker-indicator { color-scheme: dark; }

  .table-bordered th,
  .table-bordered td,
  .table th,
  .table td .zui-table th,
  .zui-table td {
    border-top-color: hsl(0deg 0% 100% / 7%);
  }
  .border {
    border: 1px solid hsl(0deg 0% 100% / 7%) !important;
  }
  .table td {
    color: #afb0b1 !important;
  }
  .form-control,
  .css-1s2u09g-control {
    // color: #fff;
    color: hsl(0deg 13% 72%) !important;
    background-color: rgb(255 255 255 / 0%);
    border: 1px solid #5a7fa8 !important;
    border-radius: 4px;
  }
  ::placeholder {
    color: hsl(0deg, 13%, 72%) !important;
  }

  .css-14el2xx-placeholder {
    color: hsl(0deg 13% 72%) !important;
  }

  .css-qc6sy-singleValue {
    color: #b2d4ff !important;
  }

  // .css-dbbd1j-MenuList:hover{ background-color: #fff !important; color: hsl(0deg 13% 72%) !important;}
  // // .css-yt9ioa-option:active{background-color:red !important;}
  // .css-1n7v3ny-option{background-color:#FFF;}
  // .css-1n7v3ny-option:hover{
  //   //background-color:#fff;
  // background-color:hsl(0, 0%, 100%)
  // }
  .css-26l3qy-menu {
    // background-color: #fff !important;
    // color: hsl(0deg 13% 72%) !important;
    background-color: rgb(9, 9, 9) !important;
    color: rgb(255, 255, 255) !important;
  }
  .css-26l3qy-menu:hover {
    //background-color: #fff !important;
    color: rgb(255, 255, 255) !important;
  }
  //,{color:red !important;}
  // .css-1gtu0rj-indicatorContainer:hover{color:hsl(0, 0%, 20%);}

  .modal-content {
    border: 1px solid #3c4156 !important;
    background: #020517 !important;
  }
  .modal-header {
    color: #d1e3f7;
  }
  .comment-heading {
    color: #d1e3f7;
  }
  .audit-trail-row {
    color: #d1e3f7;
  }
  .audit-trail-level {
    color: #ababab !important;
  }
  .audit-trail-email {
    color: #ababab !important;
  }
  .audit-trail-name {
    color: #ababab !important;
  }
  .comment-thread {
    color: #fff !important;
  }

  .table,
  .zui-table,
  .zui-table-rounded .card-body .badge {
    color: #fff8fc !important;
  }
  .table-hover tbody tr:hover,
  .zui-table tbody tr:hover {
    color: #ffffff;
    background-color: rgb(0 0 21 / 29%);
  }
  .zui-table-rounded tbody td {
    border: none;
    border-top: solid 1px #ddeeee;
    background-color: #020517;
  }
  .page-item.disabled .page-link,
  .page-link {
    color: #e1e1e1;
    background-color: hsla(0, 0%, 100%, 0.075);
    border-color: #181924;
  }
  .page-item.active .page-link {
    color: #e1e1e1;
    background-color: #1574da;
  }
  label {
    color: #9e9a9a;
  }
  .table .btn-sm,
  .table .btn-group-sm > .btn {
    border: 1px solid #6a6b6e;
    color: #aeb1b6;
  }
  .table svg.c-icon {
    color: #e7e9ed;
  }
  .card-footer {
    background: #20202a;
    border-color: #181924;
    color: #afb0b1;
  }
  .table thead th {
    border-bottom: 0px !important;
  }
  .card-body thead {
    background: #2196f3;
  }
  .card-body tbody {
    background: #2e2e34;
  }
  .card-body .badge {
    border: 1px solid #585a5d !important;
    color: #afb0b1 !important;
  }
  .data-actions .btn {
    border: 1px solid #6a6b6e !important;
  }
  .approve-fieldwrap legend {
    color: #9e9e9e;
  }
  .approveinfo {
    color: #9e9e9e;
  }
  .incomeblock,
  .incomeblock header.card-header h4 {
    color: #b4b4b4 !important;
  }
  .incomeblock .card-accent-secondary {
    border: 1px solid #808080 !important;
  }
  .incomeblock header.card-header {
    background: none;
    border-top: 1px solid #656565;
  }
  .incomeblock .card-accent-secondary:hover {
    border: 1px solid #ffffff !important;
  }
  .incomeblock:hover,
  .incomeblock header.card-header:hover h4 {
    color: #ffffff !important;
  }
  .headwidth .table-bordered th,
  .headwidth .table-bordered td {
    padding: 10px 10px !important;
    font-weight: normal;
    font-family: "Roboto";
    font-size: 13px !important;
    color: #343434;
    border: 0px !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid !important;
    border-color: #555050 !important;
  }
  .reciept_temp_three .card-body .table-bordered th,
  .reciept_temp_three .card-body .table-bordered td {
    border: 0px !important;
    font-size: 13px;
    font-family: "Roboto";
  }
  .income_templates .table-bordered th,
  .income_templates .table-bordered td {
    border: 0px !important;
  }
  .invoicetemplatethree .printable .card-body,
  .invoicetemplateone .printable .card-body,
  .invoicetemplatetwo .printable .card-body {
    background: #fff;
  }
  .printareainvoice td {
    color: #fff !important;
  }

  .card-header {
    background-color: #020517;
    border-color: #626264;
  }

  .incomeformswrap .receiptdata table td {
    color: #fff !important;
  }

  .report-filters {
    border-radius: 15px;
    background-color: #020517;
    border: 1px solid #5a7fa8;
  }
  .c-header .c-header-toggler-icon,
  .noticationIcon {
    filter: invert(55%) sepia(100%) saturate(27%) hue-rotate(109deg)
      brightness(236%) contrast(105%);
  }
  // .noticationIcon{color:rgb(53, 53, 53);}
  .tenant-logo {
    border: 2px solid rgb(209, 227, 247);
    width: 70px;
    height: 70px;
    border-radius: 120rem;
  }
  .bulk-quantity-buttons {
    text-align: center;
    color: #fff !important;
    border: 1px solid #5a7fa8 !important;
    background: black;
  }
  .c-switch-pill .c-switch-slider {
    background: black;
  }
  .edit-bulk-input {
    color: #b2d4ff !important;
  }
}

//End of dark mode styles

[data-theme="dark"] .c-header .c-header-nav .c-header-nav-link,
[data-theme="dark"] .c-header .c-header-nav .c-header-nav-btn,
[data-theme="dark"] .c-sidebar .c-sidebar-nav-link,
[data-theme="dark"] .c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: #9e9e9e !important;
} //---------------END DARK THEME STYLING-----------------------
