// Here you can add other styles
.c-sidebar-brand {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 0 56px;
  flex: 0 0 56px;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: center;
  justify-content: left;
  padding: 0.7em;
  // background: #1574da !important;
}

.c-sidebar .c-sidebar-brand {
  background: none !important;
}
.hidden {
  display: none;
}
.fillDiv {
  max-width: -webkit-fill-available;
  max-width: -moz-available;
}
.wd .col:nth-child(1),
.wd .c-vr {
  display: none !important;
}

.wd .card-body {
  padding: 0.7rem !important;
}

.c-sidebar {
  background: #fff;
}

.c-sidebar-nav-link,
.c-sidebar-nav-dropdown-toggle {
  // color: #545353 !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff !important;
}

.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #333;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 254, 254, 0.6) !important;
}

.c-sidebar .c-sidebar-nav-title {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #5a7fa8;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  font-weight: 600;
  background: #eff6ff;
  color: #333 !important;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #009fe3 !important;
  background: #eff6ff;
}

.c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #009fe3 !important;
}

.rai-container {
  margin-top: -0.3rem;
  margin-bottom: -0.1rem;
}

.receiptdetail:hover {
  outline: 0;
  background: none !important;
  box-shadow: 0 0 0 0.2rem rgb(82 168 255 / 50%) !important;
}

.detailicon:hover {
  color: #3c4b64 !important;
}

.pixelated {
  image-rendering: pixelated;
}
.info-color {
  color: #3399ff;
}

.dark-color {
  color: #333;
}

@media print {
  //Hide last columns that have py-2 and pr-4 classes (Actions columns)
  table tr td.py-2:last-child,
  table tr th.pr-4:last-child {
    display: none;
  }

  // col-3 for reports, card-header-actions for buttons, then rest for filter, items per page, pagination
  .card-header-actions,
  .col-3,
  .datefilter,
  svg,
  .c-datatable-filter,
  .c-datatable-items-per-page,
  .pagination {
    display: none !important;
  }
}

.btn-green {
  background-color: #1d6f42;
  color: #fff;
}
// PREVIEW
.prev .table-bordered th,
.prev .table-bordered td,
.prev1 .form-control,
.prev .form-control {
  border: 0px;
}
.prev1 .table th,
.prev1 .table td,
.prev .table th,
.prev .table td {
  vertical-align: top;
  border-top: 1px solid;
  border-top-color: #d8dbe0;
}
.prev1 .table td input,
.prev .table td input {
  text-align: center;
}
.psp .table td input {
  text-align: left; //Payslip Preview
}
.psp .table td input.right {
  text-align: right; //Payslip Preview
}
.psp .table th.right {
  text-align: right; //Payslip Preview
}
.prev1 .table-bordered th,
.prev1 .table-bordered td {
  border: 1px solid grey;
}
.p-bordered-bot {
  border-bottom: 1px solid grey;
  padding-bottom: 0.2em;
}

/*NEW ADDITIONAL STYLES ADDED BY HERBERT*/
.container-fluid {
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 400;
}
.table {
  width: 100%;
  // margin-bottom: 1rem;
  color: #3c4b64;
}
.card-header,
h1 {
  font-family: "Roboto";
  font-weight: 300;
}

input.form-control {
  border: 1px solid rgba(33, 150, 243, 0.63);
  box-sizing: border-box;
  border-radius: 4px;
  // padding: 18px 15px;
  padding: 9px 10px;
  font-size: 0.75rem;
  color: black;
  min-height: 38px !important;
}
label {
  display: inline-block;
  margin-bottom: 0px;
  font-size: 0.815rem;
  font-family: "Roboto";
  font-weight: normal;
  color: #000000;
}
.table {
  width: 100%;
  margin-bottom: 0 !important;
  color: #3c4b64;
}

.btn-primary {
  color: #fff;
  background-color: #1574da;
  border-color: #1574da;
}
.invoicetotals td {
  text-transform: uppercase;
  color: #000;
}
//Status button styling
.card-body .badge {
  display: inline-block;
  padding: 5px 5px;
  font-size: 11px;
  font-weight: normal;
  color: #545353;
  white-space: nowrap;
  vertical-align: top;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  text-align: left;
  max-width: 90px;
}
.card-body .badge:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 20px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
}
.card-body .badge-success,
.card-body .badge-warning,
.card-body .badge-primary,
.card-body .badge-danger,
.card-body .badge-secondary {
  background: none !important;
}
.card-body .badge-warning:before {
  background-color: #f9b115;
}
.card-body .badge-success:before {
  background-color: #00c156;
}
.card-body .badge-primary:before {
  background-color: #1574da;
}
.card-body .badge-danger:before {
  background-color: #fb1010;
}
.card-body .badge-secondary:before {
  background-color: #8a93a2;
}
.card-body .badge-purple:before {
  background-color: #7851a9;
}
.card-body .badge-orange:before {
  background-color: #ff6500;
}
//Pagination
.page-item.active .page-link {
  background-color: #1574da;
  border-color: #1574da;
}

//Page heading styles

.header-title {
  font-size: 1.4rem;
}
.card {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid;
  background-color: rgb(246 244 244 / 0%) !important;
  border-bottom-color: rgba(25, 118, 210, 0.47) !important;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  border-top: 1px solid;
  /* background-color: #fff; */
  background-color: rgb(246 244 244 / 0%) !important;
  border-color: #d8dbe0;
}

.c-footer {
  color: #3c4b64;
  background: #ebedef;
  border-top: 1px solid #d8dbe0;
  font-size: 12px;
  font-family: "Roboto";
}
.c-footer a {
  color: #3c4b64;
}
.c-footer a:hover {
  color: #1574da;
}

//Login and forgot password
.loginblock {
  font-family: "Roboto";
  font-weight: normal;
  max-width: 63%;
}
.loginblock .card-group {
  min-height: 400px;
}
.loginblock h1 {
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 2px;
}
.loginblock .card-body {
  padding: 0px;
}
.loginblock .p-4.card {
  width: 50%;
  -webkit-flex: none;
  flex: none;
}
.loginblock p.text-muted {
  margin-bottom: 2rem;
  font-size: 13px;
}
.backtomain {
  margin-bottom: 25px;
  display: block;
}
.loginblock a {
  color: #768192;
  font-size: 13px;
}
.loginblock .btn-link:hover {
  color: #1574da;
}
.loginbtn {
  background: #1574da;
  width: 100%;
}
.loginbtn:hover {
  background: #0858af;
}

//Login for tabs and mobile phone
@media screen and (max-width: 767px) {
  .loginblock {
    max-width: 90%;
    overflow: hidden;
  }
  #imageCarouselBlock {
    display: none !important;
  }
  #p-4Card {
    width: 40% !important;
  }
  .loginblock .p-4.card {
    width: 100%;
    -webkit-flex: none;
    flex: none;
  }
  .c-header .c-header-brand {
    background-color: #1574da;
    margin-left: 0 !important;
  }
  .header-nav2 {
    display: inline-block;
    width: 28%;
    padding-top: 10px;
  }
  .header-nav2 .c-header-nav-item {
    // display: inline-block;
  }
  .notif-item {
    display: none !important;
  }
}

// .topbtn {
//   font-size: 12px;
// }

button.modebtn {
  background: none;
  border: 1px solid #8e8e8e !important;
  border-radius: 50px;
  font-size: 11px;
  padding: 2px 12px;
  margin-right: 10px;
  color: #8e8e8e !important;
  font-family: "Roboto";
  overflow: hidden;
  transition: ease-in-out all;
}
button.modebtn:active,
button.modebtn:hover {
  border-radius: 50px;
  background: #1574da;
  color: #ffffff !important;
  transition: ease-in-out all;
}

.actionicon .btn-sm {
  padding: 0.1rem 0.25rem;
  font-size: 0.4rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.actionicon .btn-danger,
.actionicon .btn-info {
  color: #8b8c8e;
  background: none;
  border-color: #72767b;
}
select.themeselect {
  border: 1px solid #ccc;
  padding: 2px 4px;
  font-size: 12px;
  border-radius: 3px;
}
// .actionicon .btn-danger, .actionicon .btn-info {
//   color: #8b8c8e;
//   background: none;
//   border-color: #72767b;
// }

//INCOME PAGE STYLES
.incomeblock > .text-center{
  cursor: pointer;
  border: 1px solid rgba(33, 150, 243, 0.49);
  border-radius: 10px !important;
}
.incomeblock header.card-header {
  border: 0px;
  padding: 0px;
  border-top: 1px solid #ccc;
  padding-top: 16px;
}
.incomeblock header.card-header h4 {
  font-family: "Roboto";
  font-weight: 100;
  font-size: 1.1rem;
  margin-bottom: 0px;
  color: #000000;
}
.incomeblock span {
  padding: 20px 0px;
  background: #EDF5FF;
  border-radius: 9px 9px 0px 0px;
  /* border-top: 1px solid #ced2d8 !important; */
}
.incomeblock .c-icon {
  width: 100px !important;
  height: 100px !important;
}
.incomeblock .card:hover {
  border: 1px solid #1574da;
}
.incomeblock .card-accent-secondary {
  border-top: 1px solid rgba(33, 150, 243, 0.49) !important;
  min-height: 220px;
}

// End of income page styles

.header-btn{
  background: #FFFFFF;
  border: 1px solid #2196F3;
  box-shadow: 0px -2px 6px rgba(33, 150, 243, 0.31);
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #2196F3;
}

.export-btns{
  width: 100%;
  border: 0 !important;
}

.csv-btn{
  border: 0 !important;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 0;
    background: none;
}

.c-datatable-filter input{
  background: rgba(33, 150, 243, 0.13);
border: 1px solid rgba(33, 150, 243, 0.65);
border-radius: 4px;
}

select.form-control{
  background: rgba(33, 150, 243, 0.13);
  border: 1px solid rgba(33, 150, 243, 0.65);
  border-radius: 4px;
}

.table .btn-sm {
  background: none;
  border: none !important; //1px solid #3c4b64;
  color: #3c4b64;
}
.table .btn-sm:hover {
  color: #ffffff;
}
.table svg.c-icon {
  color: #3c4b64;
}
.table .btn-sm:hover {
  // background: #1574da;
  border: 1px solid #1574da;
}
.table .btn-sm:hover svg.c-icon {
  color: #ffffff;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.1rem 0.3rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
  width: 0.9rem;
  height: 0.9rem;
}
.incomeblock {
  // max-width: 31%;
  min-width: 23%;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.stripright {
  width: 37%;
  // border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 16px;
}
.stripbulk {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.stripleft {
  width: 60%;
  // display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
}
.stripwrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.stripleft.stripdata .form-group {
  width: 48%;
}
.stripleft.stripdata {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

// .stripleft .form-group {
//   width: 48%;
//   display: block;
// }

.approvewrap button,
.approvewrap a {
  margin-right: 9px;
  color: #fff;
}
.approvewrap a:hover {
  color: #fff !important;
}
.btn-main {
  background: rgb(36, 46, 92);
}
.btn-main:hover {
  color: #ffffff;
}

// MISC STYLES
.loginform > .form-control:focus {
  z-index: 0 !important;
}
.thumb {
  max-width: 100px;
}

/*AUDIT TRAIL STYLES*/
fieldset.approve-fieldwrap {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 15px;
}
.approvedetail img.thumb.img-thumbnail {
  height: 60px;
  width: 60px;
  border-radius: 100px;
  margin-right: 10px;
  padding: 3px;
}
.approvedetail {
  display: flex;
  flex-wrap: wrap;
}
.approve-fieldwrap legend {
  display: block;
  width: auto;
  padding: 0px 10px;
  margin-bottom: 0px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 0px;
  margin-left: 0px;
}

.approvalwrap {
  padding: 20px 15px 0px 15px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.container2 {
  height: 50vh;
  width: 100vw;
}



.container-cropper {
  height: 300px;
  width: 40px;
}
.cropper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 40px;
}
.slider {
  align-items: center;
  width: 90%;
  position: absolute;
  align-content: center !important;
  justify-content: center;
  margin-bottom: 0;
}
.containerbuttons {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 15px;
  display: flex;
  align-items: center;
}
.widelength {
  width: 100% !important;
  display: block;
}
.approveinfo span {
  font-size: 12px !important;
}

.approveinfo h4 {
  margin: 0px;
  padding: 0px;
  font-size: 15px;
  font-weight: 500;
  margin-top: 12px;
  text-transform: capitalize;
}

.approveblock {
  width: 25% !important;
  margin-bottom: 20px;
  display: inline-block;
}

.approveblock h5 {
  margin-bottom: 5px;
  color: #858585;
  font-size: 13px;
}
.cropContainer {
  position: absolute;
  width: 100%;

  height: 400px;
}

//Data action styles for Excel, Copy, Print ETC
.card-header-actions {
  display: flex;
  flex-wrap: wrap;
  flex-flow: row-reverse;
}
.data-actions {
  margin-right: 16px;
}
.data-actions .btn:hover {
  border: 1px solid #1574da !important;
  color: #ffffff !important;
  background: #1574da !important ;
}
.data-actions .btn {
  padding: 3px 11px;
  font-size: 12px;
  vertical-align: bottom;
  background: none;
  border: 1px solid #ccc !important;
  color: #848484 !important;
  margin-top: 6px;
}

.back-actions .btn {
  font-size: 11px;
  vertical-align: bottom;
  background: none;
  border: 1px solid #ccc !important;
  color: #3399ff !important;
}

.card-actions .btn {
  padding: 3px 8px;
  font-size: 12px;
  vertical-align: bottom;
  background: none;
  border: none !important;
  color: #848484 !important;
  margin-top: 0px;
}

.border-none .card-body .badge {
  border: none;
  font-size: 12px;
}
.paysliplists .mb-3 .card-body,
.paysliplists .mb-3 .card-header {
  padding: 10px 15px 5px 15px;
}
.paysliplists h5 {
  font-size: 15px;
}
.paysliplists h4.card-title {
  font-size: 16px;
}
.paysliplists .my-3 {
  margin-bottom: 30px !important;
}

// .actionicon svg.c-icon {
//   margin-top: 2px;
//   margin-right: 3px;
//   vertical-align: middle;
//   margin-bottom: 2px;
// }

// .collapse .card-body {
//   padding: 15px 15px;
// }

.dashstatus {
  width: 100px;
  text-align: left !important;
}
.invoicedata .col-md-7 table.table.table-bordered {
  width: 61%;
  float: right;
}
// .invoicedata .table-bordered th:first-child, .invoicedata .table-bordered td:first-child {
//   width: 57%;
// }
.invoicedata .table-bordered th,
.invoicedata .table-bordered td {
  font-size: 13px;
  color: #343434 !important;
  font-weight: normal;
  border: 0px;
}
.invoicedata .table-bordered tr {
  border-bottom: 1px solid #ccc;
}
.invoicedata input.form-control {
  border: 0px !important;
}
.headerright td {
  font-size: 13px;
}
.headerleft p {
  font-size: 15px;
}
.invoicetemplatethree .card-header {
  color: #505253;
  padding: 0px;
  margin: 0px 20px;
  margin-top: 30px;
  border-bottom: 2px double #b2b2b2;
}
.invoicetemplatethree .headerleft .img-thumbnail,
.invoicetemplateone .img-thumbnail {
  width: auto;
  height: 100px;
  margin-bottom: 10px !important;
  background: 0px;
  border: 0px;
  padding: 0px;
}
.invoicetemplatethree .headerleft p {
  font-size: 13px;
  color: #343434 !important;
  font-family: "Roboto";
  font-weight: normal;
}
.invoicetemplatethree .headerright td {
  font-size: 13px;
  padding: 7px 11px;
  color: #343434 !important;
  font-family: "Roboto";
  font-weight: normal;
}
.invoicetemplatethree .headerright h1 {
  font-weight: bold;
  font-size: 64px !important;
  color: #848484;
}
.invoicetemplatethree .billinginfo {
  width: 45%;
}
.invoicetemplatethree .billinginfo p {
  font-size: 13px;
  color: #343434 !important;
  border-bottom: 1px solid #e7e6e6;
  padding: 3px 0px;
  font-family: "Roboto";
}
.invoicetemplatethree table#tab_logic {
  margin-bottom: 0px !important;
}
.invoicetemplatethree .bankinfo {
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  color: #343434 !important;
  font-family: "Roboto";
}
.invoicetemplatethree .bankinfo p {
  margin: 4px 0px;
  font-size: 13px;
  color: #343434 !important;
  font-family: "Roboto";
}
.invoicetemplatethree .printable.card {
  border: 0px;
}

.invoicetemplatethree .billinginfo h5,
.invoicetemplatethree .bankinfo h5 {
  font-size: 14px;
  font-weight: bold !important;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  color: #000;
  padding-bottom: 5px;
  margin-bottom: 0px;
}
.invoicetemplatethree .bankinfo {
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  width: 45%;
  color: #343434 !important;
  margin-top: 40px;
}
.invoicetemplatethree .bankinfo h5 {
  margin-bottom: 0px !important;
  font-weight: normal;
}
.invoicetemplatethree .row.invoicedata {
  float: right;
  margin-right: 0px !important;
  width: 50%;
  margin-left: 0px;
}
.invoicetemplatethree .headerright.col {
  max-width: 39%;
}
.invoicetemplatethree .row.invoicedata table {
  width: 310px !important;
  float: right;
}
.invoicetemplatethree .totalwrap {
  width: 100%;
}
.invoicetemplatethree .invoicedata .table-bordered th,
.invoicetemplatethree .invoicedata .table-bordered td {
  /* width: 57%; */
  padding: 7px 11px;
  border: 1px solid #ccc !important;
}
.reciept_temp_one .invoiceheaders .col-4 {
  max-width: 45%;
  width: 100%;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  margin-right: 15px;
}
.receiptbillinfo {
  display: flex;
  justify-content: space-between;
}
.receiptbillinfo h5 {
  padding: 5px 10px !important;
  font-weight: normal;
  font-size: 13px;
  background: #f3f3f3 !important;
  color: #444 !important;
  border: 1px solid #e5e5e5;
}
.reciept_temp_one .receiptbillinfo .col {
  width: 100% !important;
  max-width: 46%;
}
.reciept_temp_one .receiptbillinfo p {
  padding-left: 10px;
}
.receiptbillinfo p {
  border-bottom: 1px solid #e9e9e9;
  padding: 1px 0px;
  color: #343434 !important;
  font-size: 13px;
}
.reciept_temp_one .invoiceheaders table {
  font-size: 13px;
  color: #fff !important;
}
.receiptdata tr {
  background: none !important;
  color: #343434 !important;
  border: 0px !important;
}
.receiptdata td {
  border: 1px solid #ccc !important;
}
.reciept_templates p {
  color: #343434 !important;
  font-family: "Roboto";
  font-weight: normal;
  font-size: 13px !important;
  margin: 0px;
}

.reciept_temp_one .receiptdata .soucetemplatetables {
  width: 300px;
  float: right;
}

.reciept_temp_two .card-header h1 {
  font-weight: 300;
  text-align: center;
  font-family: "Roboto";
  color: #343434 !important;
  font-weight: bold;
  font-size: 50px;
}
.receipttwo_header {
  border-bottom: 3px double #ccc !important;
  padding: 0px;
  margin: 0px 40px;
  margin-top: 5px;
}
.reciept_temp_two .card-body {
  padding: 10px 40px;
}
.tablehideinput input.form-control {
  border: 0px;
  font-size: 13px;
}
.receipttwo_header label {
  text-transform: uppercase;
  color: #343434 !important;
  font-weight: bold;
  font-size: 14px;
  margin-top: 12px;
}
.reciept_temp_two .invoiceheaders img.img-thumbnail {
  float: right !important;
  max-width: 130px;
}
.receipt_two_top_header .my-0.invoiceheaders.row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.reciept_templates .signoffleft p {
  font-size: 12px !important;
}
.reciept_temp_three .printable.card {
  border: 0px;
}
.reciept_temp_three input.form-control {
  color: #343434 !important;
  font-weight: normal;
  font-size: 13px;
  font-family: "Roboto";
  border: 0px;
}
.reciept_temp_three th {
  color: #343434;
  font-family: "Roboto";
  font-size: 13px;
}
.reciept_temp_three header.card-header .my-0.row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.reciept_temp_three h1 {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 60px !important;
}
.headwidth td {
  padding: 7px 15px;
  font-size: 13px;
}
.topheadtemp.card-header {
  margin: 0px 30px !important;
  padding: 0px;
  margin-top: 10px !important;
  border-bottom: 2px double #ccc;
  border-color: #ccc !important;
}
.reciept_temp_three .issueinfosum h6 {
  text-transform: uppercase;
  border-bottom: 0px;
  width: 100%;
  display: block;
  font-family: "Roboto";
  padding-bottom: 0px;
  font-size: 14px;
  color: #343434 !important;
  font-weight: bold;
}
.reciept_temp_three .headwidth tr {
  border-bottom: 1px solid #ccc;
}
.reciept_temp_three .card-body {
  padding: 0px;
  margin: 20px 30px;
}
.reciept_temp_three .templatesignoff {
  margin: 0px 30px;
}
.issueinfosum p {
  width: 130% !important;
  border-bottom: 1px solid #e5e3e3;
  padding: 4px 0px;
}
.reciept_temp_three .headwidth {
  width: 100%;
  max-width: 320px;
  color: #343434 !important;
  font-family: "Roboto";
  font-weight: normal;
}

.reciept_temp_two .printable.card {
  border: 0px;
  padding: 0px;
  margin: 0px;
}
.reciept_temp_two .col-12.col-sm-6.col-md-12 {
  margin: 0px;
  padding: 0px;
}

.reciept_temp_one table h1 {
  font-size: 60px !important;
  font-weight: bold;
}
.reciept_temp_one .invoiceheaders h1 {
  font-size: 70px;
  font-weight: bold;
  margin-bottom: 0px;
}

.reciept_templates .card-body {
  background: #fff;
}
.income_templates .signoffright img,
.reciept_templates .signoffright img {
  width: 50px;
  height: auto;
  text-align: right;
}
.income_templates .templatesignoff,
.reciept_templates .templatesignoff {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  text-align: right;
  border-top: 2px double #ccc;
  padding-top: 19px;
}
.income_templates .templatesignoff .signoffleft {
  margin-top: 6px;
  padding-right: 20px;
  color: #343434 !important;
  font-size: 12px;
}
.invoicetemplatethree .invoicedata .table-bordered th:first-child,
.invoicetemplatethree .invoicedata .table-bordered td:first-child {
  width: 100%;
}
.invoicetemplateone .invoiceheaders {
  display: flex;
  justify-content: space-between;
}
.invoicetemplateone header.card-header {
  background: none !important;
  margin: 0px 20px;
  margin-top: 20px;
  padding: 0px;
}
.invoicetemplateone .darkinvoice {
  background: #6c6c6c;
  padding: 5px ​20px !important;
  color: #fff;
}
.invoicetemplateone .darkinvoice h1 {
  font-size: 61px;
  font-weight: bold;
  margin-bottom: 0px;
}
.invoicetemplateone .darkinvoice table {
  color: #fff;
}
.invoicetemplateone .temponemedal .col {
  width: 100% !important;
  max-width: 40%;
}
.invoicetemplateone .temponemedal {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.invoicetemplateone .logicone tr {
  border-bottom: 1px solid #ccc !important;
}
.invoicetemplateone .temponemedal h5 {
  font-size: 13px;
  padding: 6px 4px;
  font-weight: lighter;
  color: #191919 !important;
  background: #ebebeb !important;
  font-weight: bold !important;
  border: 1px solid #d7d3d3 !important;
}
.recieptdata.col-4 {
  height: 200px;
}
.invoicetemplateone .invoiceone_number {
  padding: 10px 20px;
  background: #6c6c6c;
  margin-right: 15px;
  margin-bottom: 20px;
}
.invoicetemplateone .temponemedal p {
  border-bottom: 1px solid #ebebeb;
  font-size: 13px;
  color: #343434 !important;
  padding: 1px 7px;
}
.income_templates #tab_logic td {
  border: 0px;
}
.income_templates #tab_logic tr {
  border-bottom: 1px solid #ccc !important;
  border-top: 0px !important;
}
.invoicetemplateone .invoiceone_number .table-bordered th,
.invoicetemplateone .invoiceone_number .table-bordered td {
  border: 1px solid;
  border-color: #d8dbe0;
  padding: 9px 10px;
  font-size: 13px;
  font-weight: normal;
  color: #fff;
}

.soucetemplatetables td {
  padding: 8px 10px !important;
  font-size: 13px;
  color: #343434;
}

.invoicetemplateone .printable.card {
  border: 0px;
}
.invoicetemplateone .temponelogic {
  margin: 0px;
  /* width: 300px; */
}
.invoicetemplateone .temponelogic table {
  max-width: 300px;
  width: 100%;
  float: right;
}
.payterms table.table.soucetemplatetables.no-table-bordered {
  max-width: 100%;
}
.invoicetemplateone .logicone {
  width: 100%;
}
.invoicetemplateone .payterms,
.invoicetemplateone .payterms table {
  width: 100%;
}
.invoicetemplateone .invoiceheaders p {
  font-size: 13px;
  padding-bottom: 0px;
  color: #343434 !important;
  font-family: "Roboto";
  font-weight: normal;
}
.invoicetemplateone .invoiceone_number.col {
  max-width: 40%;
}
.invoicetemplatetwo .col-12 {
  padding: 0px;
}
.invoicetemplatetwo .miniheader h1 {
  text-align: center;
  font-weight: bold;
  font-size: 52px;
  color: #7c7c7c !important;
  font-family: "Roboto";
}
.invoicetemplatetwo .miniheader label {
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 10px;
  color: #343434 !important;
}
.invoicetemplatetwo .miniheader p {
  font-size: 14px;
  color: #343434 !important;
  font-weight: bold;
}
.invoicetemplatetwo .miniheader.card-header {
  padding: 0px;
  margin: 0px 40px;
  border-bottom: 3px double #ccc;
  margin-top: 2px;
  padding-bottom: 0px;
  background: none;
}
.invoicetemplatetwo .card-body {
  padding: 15px 40px;
}
.invoicetemplatetwo .printable.card {
  border: 0px;
}
.invoicetemplatetwo .invoiceheaders {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
  padding: 0px;
  margin-top: 10px !important;
}
.invoicetemplatetwo .invoiceheaders .col-4 {
  max-width: 45%;
  width: 100%;
  padding: 0px;
  font-size: 14px;
}
.invoicetemplatetwo .invoiceheaders .col-4 p {
  padding: 2px 0px;
  color: #343434 !important;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
}
.invoicetemplatetwo .img-thumbnail {
  width: auto;
  height: 110px;
  margin-bottom: 0px !important;
  background: 0px;
  border: 0px;
  padding: 0px;
  float: right;
}
.invoicetemplatetwo .invoiceheaders .form-group {
  margin-bottom: 0px;
}
header.receipt_two_top_header.card-header {
  background: #f0f0f0;
  padding: 15px 30px;
}
.invoicetemplatetwo .card-header {
  background-color: #efefef;
}
.billingtoinfo h6 {
  font-size: 14px;
  border-bottom: 1px dashed #d9d9d9;
  width: 100%;
  margin-left: 15px !important;
  margin-right: 15px !important;
  color: #ce0909 !important;
  text-transform: uppercase;
  padding-bottom: 1px;
  margin-bottom: 5px;
  font-weight: bold;
}
.recieptdata.col-4 {
  background: #424242 !important;
}
.temptwodetails.col-4 {
  padding: 0px;
  font-size: 13px;
  color: #343434 !important;
  margin-top: 37px;
}
.temptwodetails p {
  padding-bottom: 3px;
  margin: 0px;
  color: #343434 !important;
  font-size: 13px;
  font-family: "Roboto";
}
.income_templates table#tab_logic .form-control {
  color: #343434 !important;
  font-size: 13px !important;
  font-family: "Roboto" !important;
  border: 0px;
}
.income_templates table#tab_logic thead th {
  color: #343434 !important;
  font-size: 13px !important;
  font-family: "Roboto" !important;
  padding: 7px 10px;
  background: #f3f3f3;
  font-weight: bold;
  border: 0px !important;
}
th.amounttitle {
  text-align: right;
}
.income_templates table#tab_logic thead th.text-center {
  text-align: left !important;
}
.billinginfo p {
  margin-bottom: 0px;
}
.income_templates table#tab_logic thead td {
  border: 0px !important;
}
.payterms td {
  border: 0px !important;
  color: #343434 !important;
}
.billingtoinfo .col-4 {
  font-size: 13px;
  color: #343434 !important;
}
.billingtoinfo {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.invoicetemplatetwo .row.temptwoinvoicedata {
  float: right;
  // width: 300px;
  width: 300px;
}
.temptwodetails h5 {
  margin-bottom: 0px !important;
  color: #ce0909 !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 3px;
}

.incomeformswrap .receiptdata table {
  width: 300px;
  float: right;
}

.incomeformswrap #taxeslesstotals,
.incomeformswrap .taxeslesstotals {
  width: 135px;
}
tr.invoicetotals {
  background: #f0f0f0;
}
.taxtablelist {
  width: 287px;
}
.taxtablelist .taxtableleft {
  width: 70%;
}
h5.respayment {
  color: #242e5c;
  font-weight: normal;
}

/*========PAYSLIP TEMPLATE STYLES===========*/

.paysliplists .col-sm-6.col-lg-4:nth-child(2n) .card {
  background: #fcfcfc;
}
.paysliplists .col-sm-6.col-lg-4:nth-child(2n) .card header.card-header {
  background: #fcfcfc;
}
.paytemplateheader .col-4 {
  width: 50% !important;
  max-width: 100% !important;
  justify-content: space-between !important;
  margin: 0px;
  padding: 0px;
}
.paytemplateheader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0px !important;
  padding: 0px !important;
}
.paytemplateheader h1 {
  font-size: 60px !important;
  text-align: justify;
  font-weight: bold;
  color: #444444;
  margin-bottom: 0px;
}
.paytemplateheader p {
  font-size: 15px;
  text-align: left;
  margin-bottom: 0px;
}
.paysliptabledata td,
.paysliptabledata th {
  font-size: 13px;
  padding: 7px 13px !important;
  color: #343333;
}
.paysliptabledata td span {
  display: block;
  font-weight: bold;
}
.payslipfooter header.card-header {
  padding: 5px 20px;
  font-size: 14px !important;
  color: #343333;
  font-weight: bold;
  background: #fff;
  border-bottom: 1px solid #ccc;
}
.payslipfooter p {
  margin: 0px;
  line-height: 2em;
  font-size: 12px;
  color: #343333;
}
.payslipfooter .card-body {
  padding: 5px 20px;
}
.payslipfooter .printable.card {
  border: 1px solid #ccc;
  border-radius: 1px;
  background: none !important;
}
.payslipfooter.col-md-6 {
  margin-top: 10px;
}
.paysliptabledata {
  width: 100%;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
table.remove-table-border{
  border: 0;
}
tr.netPay-container{
  display: flex;
  justify-content: flex-end;
}
tr.netPay-container > td {
  background: rgb(242, 242, 242);
  border-right: 1px solid #d5d5d5 !important;
  border-left: 1px solid #d5d5d5 !important;
}
.paysliptabledata th,
.paysliptabledata td {
  border: 0px !important;
  border-bottom: 1px solid #d5d5d5 !important;
  color: #131313 !important;
}
td.removeBorderBottom{
  border-bottom: 0 !important;
}
th.paysliptable-amounts,
td.paysliptable-amounts{
  text-align: center !important;
  border-left: 2px solid #d5d5d5 !important;
}
.payslipcard .table-bordered th,
.payslipcard .table-bordered td {
  border: 0px !important;
  border-bottom: 1px solid #d5d5d5 !important;
}
header.preparedAndreceivedBy{
  font-size: 1rem;
  background-color: rgb(242, 242, 242) !important;
}
h4.info-color {
  color: #1574da;
  font-weight: normal;
}

/*AUDIT TRAIL DESIGN INTERFACE*/
.auditdisplay {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-bottom: 20px;
  border: 1px solid #ccc !important;
  border-radius: 10px;
  overflow: hidden;
}
.auditdisplayitem {
  width: 25%;
  border-right: 1px solid #ccc;
  padding: 10px 20px;
  font-size: 28px;
  font-weight: normal;
}
.auditdisplayitem p {
  font-size: 13px;
  font-weight: bold;
  /* color: #000; */
  margin-bottom: 0px;
}
.auditdisplayitem:nth-child(2n) {
  background: #fafafa;
}
.auditdisplayitem:last-child {
  border-right: 0px;
}
.imageattachments {
  min-height: 220px;
  overflow: hidden;
  height: 100%;
  max-height: 220px;
  margin-bottom: 10px;
}
.auditattachment {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
}
.imageattachments img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 0px;
  border: 1px solid #eeecec;
  border-radius: 0px;
}
.attachlinks {
  display: flex;
  justify-content: space-between;
}
.attachlinks a,
.attachlinks {
  color: #131313;
}
.itemattach {
  width: 30%;
  border: 1px solid #ccc;
  margin: 10px 0px;
  padding: 10px;
  border-radius: 5px;
  margin-right: 30px;
}
.itemattach:nth-child(3n) {
  margin-right: 0px;
}

// ------------------------ Site Structure Css , Header, Side Nav , Body ------------
.c-header {
  position: relative;
  width: 97%;
  margin: 25px 30px 10px;
  background: rgb(255, 255, 255);
  border-radius: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
.c-app {
  background: #d1e3f7;
}
//End Site Structure Css

//-------------- LEGACY THEME STYLE --------------------------------
[data-theme="legacy"] {
  .c-header .c-header-nav .c-header-nav-link,
  .c-header .c-header-nav .c-header-nav-btn,
  .c-sidebar .c-sidebar-nav-title {
    color: #9e9e9e !important;
  }
  .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
  .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: rgb(255 255 255 / 60%) !important;
  }

  .c-sidebar .c-sidebar-nav-link.c-active,
  .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
    font-weight: normal;
    background: #1574da;
    // color: #ffffff !important;
    color: #030303 !important;
  }
}

[data-theme="legacy"] .c-header .c-header-nav .c-header-nav-link,
[data-theme="legacy"] .c-header .c-header-nav .c-header-nav-btn,
[data-theme="legacy"] .c-sidebar .c-sidebar-nav-link,
[data-theme="legacy"] .c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: #9e9e9e !important;
}

//---------------END LEGACY THEME STYLING-----------------------
//---------------END NEW UI STYLING-----------------------
.c-sidebar {
  // background: #2f353a;
  border-bottom: 0px;
  background: rgb(35, 50, 95);
  border-radius: 0px 45px 45px 0px;
}
.main-card-header-text {
  color: #23325f;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  font-weight: 500;
}
.custom-Button {
  padding-right: 30px;
  padding-left: 30px;
  font-weight: 500;
  color: #fff;
  background: #2196f3;
  border: 1px solid #2196f3;
  box-sizing: border-box;
  box-shadow: 0px -2px 6px rgba(33, 150, 243, 0.31);
  border-radius: 5px;
}
.custom-Button:hover {
  color: #2196f3 !important;
  text-decoration: none !important;
  background: #fff !important;
  border-color: none !important;
}
.custom-Button-ghost {
  padding-right: 30px;
  padding-left: 30px;
  color: #2196f3;
  border: 1px solid #2196f3;
  box-sizing: border-box;
  box-shadow: 0px -1px 4px rgba(33, 150, 243, 0.31);
  border-radius: 5px;
}
.custom-Button-ghost-danger {
  padding-right: 30px;
  padding-left: 30px;
  color: #2196f3;
  border: 1px solid #2196f3;
  box-sizing: border-box;
  box-shadow: 0px -1px 4px rgba(33, 150, 243, 0.31);
  border-radius: 5px;
}
.custom-Button-ghost:hover {
  color: #fff;
  text-decoration: none;
  background: #2196f3;
}
custom-Button-ghost-danger:hover {
  color: #fff;
  text-decoration: none;
  background: #ea197d;
}
// .btn:hover {
//   color: #3c4b64;
//   text-decoration: none;
//   background: #fff;
// }

.css-b62m3t-container {
  border: 0px solid rgba(33, 150, 243, 0.63);
  box-sizing: border-box;
  border-radius: 4px;
}
textarea.form-control {
  height: auto;
  border: 1px solid rgba(33, 150, 243, 0.63);
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 0.8rem;
  color: black;
}
.css-1s2u09g-control {
  border-color: rgba(33, 150, 243, 0.63) !important;
}
.image-Uploaded-card {
  border: 1px solid rgba(39, 114, 195, 0.67);
  box-sizing: border-box;
  // height: 456px;
}
.image-card-header {
  background: rgba(33, 150, 243, 0.12) !important;
  border-radius: 26px 26px 0px 0px !important;
}
.image-card-body {
  overflow: scroll;
  max-height: 339px !important;
}

.audit-trail {
  margin-right: 2%;
  position: relative;
  width: 150px;
  height: 190px;
  border: 1px solid rgba(39, 114, 195, 0.44);
  box-sizing: border-box;
  box-shadow: 0px 0px 4px 2px rgba(33, 150, 243, 0.14);
  border-radius: 10px !important;
}
.audit-trail :hover {
  background: rgba(33, 150, 243, 0.12);
}
.audit-trail img.thumb.img-thumbnail {
  height: 65px;
  width: 65px;
  border-radius: 100px;
  position: absolute;
  //margin-right: 20px;
  margin-left: 12px;
  //padding: 3px;
}
.audit-trail-details-box {
  margin: auto;
  // width: 50%;
}
.audit-trail-name {
  margin: auto;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #23325f;
  margin-top: 85px;
}
.audit-trail-email {
  // margin: auto;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.54);
}
.audit-trail-level {
  margin-top: -1rem !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.54);
}
.audit-trail-row {
  justify-content: flex-start;
  // space-between;
  display: flex;
  width: auto;
  // padding: 0px 10px;
  margin-bottom: 0px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
}
.comment-headings {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #23325f;
}
.comment-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  // font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */
  letter-spacing: 0.3px;
  /* Deep Blue */
  color: #23325f;
}
.comment-thread {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* or 142% */
  letter-spacing: 0.4px;
  /* Text Black - 80% */
  color: rgba(0, 0, 0, 0.8);
}
.custom-Button-ghost.mt-2.mr-2.btn:hover {
  color: #fff;
  text-decoration: none;
  background: #ea197d;
  border: 0px none #ffffff00;
}

.bulk-request-left-tab {
  // background: rgba(209, 227, 247, 0.24) !important;
  background-color: #2196f3 !important;
  color: white;
}

.bulk-request-right-tab {
  background-color: #d1e3f7 !important;
  color: black;
}
.bulk-quantity-buttons {
  height: 39px !important;
  width: 39px !important;
  background: rgba(33, 150, 243, 0.12);
  border: 1px solid #d1e3f7;
  box-sizing: border-box;
  border-radius: 4px;
}
.bulk-quantity-input {
  margin-left: 9px;
  margin-right: 9px;
  border-radius: 5px !important;
}
.bulk-left-tab-header {
  padding-left: 0px !important;
}
.bulk-right-tab-header {
  padding-right: 0px !important;
}
.bulk-tab-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  /* identical to box height, or 213% */
  letter-spacing: 0.3px;
}
.table-curved {
  display: block;
  border: 1px solid rgba(39, 114, 195, 0.67);
  border-radius: 15px;
}
.table-curved thead {
  background: rgba(33, 150, 243, 0.12);
  border-radius: 15px 15px 0px 0px;
  //   border-top-left-radius: 23px;
  // border-top-right-radius: 23px;
  // display: table-caption;
}
.table-curved th {
  // width: 16%;
  // padding: 11px 0px 11px 0px;
  border-top: 0px;
}
.table-curved td {
  // width: 16%;
  padding: 11px 15px 3px 15px;
}
.table-curved thead th {
  border-bottom: 0px;
}

.zui-table {
  border: solid 1px #ddeeee;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  // font: normal 13px Arial, sans-serif;
}
.zui-table thead th {
  background-color: #ddefef;
  border: solid 1px #ddeeee;
  letter-spacing: 0.01rem;
  // color: #336b6b;
  padding: 10px;
  // text-align: left;
  padding-right: 15px;
  padding-left: 15px;
  // text-shadow: 1px 1px 1px #fff;
}
.zui-table tbody td {
  border: solid 1px #ddeeee;
  padding: 10px;
  padding-right: 15px;
  padding-left: 15px;
  // text-shadow: 1px 1px 1px #fff;
}
.zui-table tfoot td {
  // border: solid 1px #ddeeee;
  // padding: 10px;
  // text-shadow: 1px 1px 1px #fff;
  border-top: solid 1px #ddeeee;
  background-color: rgba(33, 150, 243, 0.12);
  padding: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #23325f;
}

.zui-table-rounded {
  border: 0;
}
.zui-table-rounded thead th {
  background-color: rgba(33, 150, 243, 0.12);
  border: none;
  // text-shadow: 1px 1px 1px #ccc;
}
.zui-table-rounded thead th:first-child {
  // border-radius: 30px 0 0 0;
  border-radius: 23px 0 0 0;
}
.zui-table-rounded thead th:last-child {
  // border-radius: 0 30px 0 0;
  border-radius: 0 23px 0 0;
}
.zui-table-rounded tbody td {
  border: none;
  border-top: solid 1px #ddeeee;
  background-color: #fff;
}
.zui-table-rounded tbody tr:last-child td:first-child {
  border-radius: 0 0 0 30px;
}
.zui-table-rounded tbody tr:last-child td:last-child {
  border-radius: 0 0 30px 0;
}
.zui-table-rounded tfoot tr:first-child {
  border-radius: 0 0 0 30px;
}
.zui-table-rounded tfoot tr:last-child {
  border-radius: 0 0 30px 0;
  // border-radius: 30px;
}
.dropdown-sm {
  padding: 0px !important;
  position: relative;
  bottom: 5px;
}
.comment-height {
  max-height: 200px;
}
.price-cards {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.invalid-feedback {
  font-size: 88%;
}

.css-1s2u09g-control.is-invalid__control
// .css-1s2u09g-control:hover
{
  border-color: #e55353 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e55353' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

  padding-right: calc(0.2em + 0.7rem);
  background-position: right calc(0.375em + 0.1875rem) center;
}
.filter-row {
  border: 1px solid #5a7fa8;
  border-radius: 9px;
}

.report-filters {
  border-radius: 15px;
  background-color: #D1E3F7;
  border: 1px solid rgb(33, 150, 243);
  margin-bottom: 25px !important;
}

.tenant-logo {
  border: 2px solid rgb(253, 253, 253);
  width: 70px;
  height: 70px;
  border-radius: 120rem;
}

//---------------END NEW UI STYLING-----------------------


//---------------BEGIN REACT TABLE STYLING-----------------------


#tableColumnDropButton:focus{
  color: #009fe3  !important;
}


.tableSearchTextbox{
  color: #009fe3  !important;
  font-size: 0.875rem !important;
  background: rgb(226, 241, 253);
  margin-left: 0 !important;
}

.rowsPerPageSelector{
  border-color: #2196F3;
  background: #E2F1FD;
  width: fit-content;
}

.tableSearchTextbox::placeholder{
  color: #009fe3 !important;
  font-size: 0.875rem !important;
}

.dropdownBox{
  width: fit-content !important;
}

.dropdownBox>input{
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

}

.no-export{
  position: initial;
}

.checkBoxLabel{
  width: 100%;
  height: 100%;
}

.checkBoxLabel:hover{
  cursor: pointer;
}

.columnFilterItem:hover{
  color: #e55353;

}

.actionButtons{
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem !important;
  padding: .5rem !important;
  min-width: auto !important;
}

.outerTableBorder{
  border: 2px solid;
  border-radius: 15px;
  border-color: #2772C3AB;
  width: 100%;
}

.actionButtonsEach:hover{
  color: #e55353 !important;
  cursor: pointer;
}

.actionButtonsEach>div{
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.actionButtonsEach>svg{
  width: 2rem;
  height: auto;
}

.noColumselected{
  width: 100%;
  height: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
}



.three-dots-btn{
  max-height: min-content;
  padding: 0px;
  width: 2rem;
}

.ReactTable thead {
  background-color: rgb(18, 108, 243, 0.8) !important;
  border-radius: 15px 15px 0px 0px;
  //   border-top-left-radius: 23px;
  // border-top-right-radius: 23px;
  // display: table-caption;
}

.customisedDropdown{
  max-height: 17rem !important;
  overflow-y: scroll !important;
}

.RTnoItems{
  width: 100%;

  padding: 1rem;
  font-size: large;
  text-align: center;
}
//////////////////////////////START READMORE COMPONENT///////////////////////////////////
///


.read-more-button {
  display: inline-block;
  margin-left: 3px;
  color: #184eca;
  cursor: pointer;
}

.read-more-button:hover {
  color: #3f56cb;
}


//////////////////////////////END READMORE COMPONENT///////////////////////////////////
///
//apply discount form
.applyDiscountForm{
 display: flex;
 gap: 1rem;
 white-space: nowrap;
 align-items: center;
 justify-content: space-between;

}

.discCInput{
  width: 70%;
}

.modalHeadingDisc{
  font-size: 2rem;
  width: 100%;
  text-align: center;
}

.model-header-border{border-top-left-radius: 16px;    border-top-right-radius: 16px;}
.model-content{ border-radius: 16px;}

.table-data-r:empty:before {
  color: hsl(0deg, 13%, 72%) !important;
  content: attr(data-placeholder);
  opacity: 1;
}

.editableDivtxt{
  background: transparent;
  border: none;
  height: 1.5rem;
}

.editableDivtxt:focus{
  outline: none;
}

.taxDropdown{
  background-color: transparent;
  height: 30px;
  background: transparent;
  width: 99%;
  border-color:transparent;
  cursor: pointer;
  font-size: 12px;
}

.disabledDropDown{
  color: #000 !important;
  border-radius: 5px ;
  border: solid 1px #2196F3 !important ;
  background-color: rgb(133, 133, 133) !important;

}

.notAllowed{
  cursor: cell;
}


//////////////////////////////END READMORE COMPONENT///////////////////////////////////

.toggleButton{
  padding: 0.5rem 2rem ;
  background: white ;
  color: #2196F3;
  border: none;
  cursor: pointer;
}
.toggleButton:hover{
  background: #68b7f7 ;
  color: white;
}

.toggleButtonSelected{
  background: #2196F3 ;
  color: white;
}

.toggleButtonWrapper{
  display: flex;
  border: 2px solid #2196F3 ;
  max-width: fit-content;
  height: fit-content;
  border-radius: 5px;
  overflow: hidden;
}
